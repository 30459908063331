
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
  background-color: $background-color;
  color: $text-color;
}

/*
 * Typography
 */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid $border-color;
}

/*
 * Links
 */

a {
  color: $accent-color;
}

a:focus, a:hover {
  color: darken($accent-color, 20%);
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid $border-color;
}

/* Sidebar navigation */
.sidebar {
  padding-left: 0;
  padding-right: 0;
}

.sidebar .nav {
  margin-bottom: 20px;
}

.sidebar .nav-item {
  width: 100%;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
}

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: $secondary-color;
  color: $text-color;
}

/*
 * Dashboard
 */



/* Object Details */

div.details-striped div.row div.details-description {
  background-color: $secondary-color;
  font-weight: 600;
  border-bottom: 1px solid $primary-color;
}

div.details-striped div.row:nth-of-type(odd) div.details-value {
  background: $primary-color;
  border-bottom: 1px solid $primary-color;
}

div.details-striped div.row:nth-of-type(even) div.details-value {
  background: $background-color;
  border-bottom: 1px solid $background-color;
}
